@tailwind base;
@tailwind components;
@tailwind utilities;

.react-chatbot-kit-chat-container {
  width: 100% !important;
}

.react-chatbot-kit-chat-bot-message {
  width: 98% !important;
  margin-left: 2% !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.react-chatbot-kit-user-chat-message {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.react-chatbot-kit-chat-btn-send{
  background-color: #2f80ed !important;
}

.react-chatbot-kit-chat-input{
  color: black !important;
  font-weight: 500;
}
body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
