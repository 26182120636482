.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bodhi-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12.5px;
  background-color: #3d4e8d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.suggestion {
  width: 75%;
  background-color: #a0a2a0;
  font-size: 10px;
  font-weight: 600;
  border-radius: 6px;
  padding: 4px;
  margin: 4px auto;
  transition: all 0.3s;
}

.suggestion:hover {
  opacity: 0.8;
  cursor: pointer;
}

.image-message-wrapper {
  width: 75%;
  background-color: #ff508b;
  font-size: 10px;
  font-weight: 600;
  border-radius: 6px;
  padding: 4px;
  margin: 4px auto;
}

.image-message {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  transition: all 0.3s;
}
.image-message > div {
  text-align: left;
}

.image-message:hover {
  opacity: 0.8;
  cursor: pointer;
}

.header-wrapper {
  height: 56px;
    color: rgb(87 87 87);
    background-color: #e2e2e2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding-left: 10px;
}

.header-text {
  font-size: 16px;
  font-weight: 600;
}

.header-subtext {
  font-size: 8px;
  font-weight: 700;
  color: rgb(44, 44, 44);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
